// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-the-teacher-js": () => import("./../src/pages/about-the-teacher.js" /* webpackChunkName: "component---src-pages-about-the-teacher-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cancellation-policy-js": () => import("./../src/pages/cancellation-policy.js" /* webpackChunkName: "component---src-pages-cancellation-policy-js" */),
  "component---src-pages-common-mistakes-js": () => import("./../src/pages/common-mistakes.js" /* webpackChunkName: "component---src-pages-common-mistakes-js" */),
  "component---src-pages-contact-us-js": () => import("./../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-requirements-js": () => import("./../src/pages/requirements.js" /* webpackChunkName: "component---src-pages-requirements-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-teaching-method-js": () => import("./../src/pages/teaching-method.js" /* webpackChunkName: "component---src-pages-teaching-method-js" */),
  "component---src-pages-tuition-fees-js": () => import("./../src/pages/tuition-fees.js" /* webpackChunkName: "component---src-pages-tuition-fees-js" */),
  "component---src-templates-post-template-js": () => import("./../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

